import { NgModule } from "@angular/core";

import { SharedStoreModule } from "@app/shared/store/shared-store.module";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { effects, profileFeatureKey, reducers } from "./store";

@NgModule({
  imports: [
    SharedStoreModule,
    StoreModule.forFeature(profileFeatureKey, reducers),
    EffectsModule.forFeature(effects),
  ],
})
export class ProfileStoreModule {}
